// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "core-js";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import VueRouterRoutes from "./router/index";
import { createGtm } from "@gtm-support/vue-gtm";
import VueHighlightJS from "vue-highlightjs";
import App from "./App.vue";
import "./styles/main.scss";

import VueClipboard from "vue-clipboard2";

var thisHostName = window.location.hostname.toLowerCase();
var isLoaded = true;

// partnerstack global store ps_xid for registration tracking
// we clear it once logged in
try {
  const query = new URLSearchParams(location.search)
  if (query && query.get('ps_xid')) {
    localStorage.setItem('ps_xid', query.get('ps_xid'));
  }
} catch (e) {
  console.log(e)
}

if (
  thisHostName.indexOf("mpmserv.co.uk") > -1 ||
  thisHostName.indexOf("mpmailserv1.co.uk") > -1 ||
  thisHostName.indexOf("mpmserv1.com") > -1
) {
  window.open("http://mpmserv.co.uk", "_self");
  isLoaded = false;
}

if (
  thisHostName.indexOf("transpond") < 0 &&
  thisHostName.indexOf("mpzmail") < 0 &&
  thisHostName.indexOf("127.0.0.1") < 0 &&
  thisHostName.indexOf("localhost") < 0 &&
  thisHostName.indexOf("amplifyapp") < 0 &&
  thisHostName.indexOf("ukemailmarketing") < 0
) {
  window.open("http://" + thisHostName + "/cp3/", "_self");
  isLoaded = false;
}

if (isLoaded === true) {
  const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes: VueRouterRoutes.routes,
    scrollBehavior(to) {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: "smooth",
        };
      } else {
        document.getElementById("app").scrollIntoView({ behavior: "auto" });
      }
    },
  });

  const app = createApp(App);
  app.use(router);
  app.use(VueClipboard);
  app.use(VueHighlightJS);
  app.use(
    createGtm({
      id: "GTM-W7KPSXK",
    })
  );

  app.mount("#app");
}
